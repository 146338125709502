import React,{Component} from 'react';

export default class ErrorMessageBox extends Component{
	
	render(){
		let errorMessages=[];
		let errorNum=0,text='';
		let type=this.props.type;
	//general errors(mostly server errors/messages)
		if(this.props.general>0){
			errorNum=this.props.general;
			
			if(type==='login'){
				if(errorNum===1)
					text='Your username or password are incorrect';
				else if(errorNum===2)
					text='You must sign in to do this';
				else if(errorNum===3)
					text='There was a problem with the server. Try again.';
				else if(errorNum===201)
					text='You must sign in before uploading';
				else if(errorNum===202)
					text='You must sign in before changing settings';

			}else if(type==='signup'){
				if(errorNum===1)
					text='Server Error';
				else if(errorNum===2)
					text='Server Error';
				else if(errorNum===3)//email already taken
					text='Email is already in use';
				else if(errorNum===4)//Username already taken
					text='Username is already in use';
				
			}else if(type==='upload'){
				if(errorNum===1)//User not logged in
					text='You must login before uploading';
				else if(errorNum===2)//input failed check
					text="Input dosen't meet requirements";
				else if(errorNum===3)//
					text='Server Error, try again';
				else if(errorNum===4)
					text='Server Failure, try again later';
				
			}
			errorMessages.push(text);
		}
	//login errors
		if(type==='login'){	
			if(this.props.pass>0){
				errorNum=this.props.pass;
				if(errorNum===3){
					text='Password contains invalid characters';
					errorMessages.push(text);
				}
			}
	//signup errors
		}else if(type==='signup'){	
			if(this.props.pass>0){
				errorNum=this.props.pass;
				if(errorNum===3){
					text='Password MUST contain an Uppercase,Lowercase, and Numbers';
					errorMessages.push(text);
				}
			}if(this.props.user>0){
				errorNum=this.props.user;
				if(errorNum===1)
					text='Username is empty';
				else if(errorNum===2)
					text='Username length has to be 3 to 20 characters'
				else if(errorNum===3)
					text='Username can only contain letters,numbers and underscores'
				errorMessages.push(text);
			}
		}	
	//login/signup erros
		if(type==='login'||type==='signup'){
			if(this.props.email>0){
				errorNum=this.props.email;
				if(errorNum===1)
					text='Email is empty';
				else if(errorNum===2)
					text='Email format is incorrect';

				errorMessages.push(text);
			}
			if(this.props.pass>0){
				errorNum=this.props.pass;
				if(errorNum===1)
					text="Password is empty";
				else if(errorNum===2)
					text='Password length has to be at least 8 characters';
				else if(errorNum===4)
					text="Passwords don't match";
				else if(errorNum===5)
					text="'Password, again' is empty";
				if(errorNum!==3)
					errorMessages.push(text);
			}

//upload image errors
		}else if(type==='upload'){
			if(this.props.title>0){
				errorNum=this.props.title;
				if(errorNum===1)
					text='Title is empty';
				else if(errorNum===2)
					text='Title contains illegal characters';
				errorMessages.push(text);
			}
			if(this.props.description>0){
				errorNum=this.props.description;
				if(errorNum===1)
					text='Description is empty';
				else if(errorNum===2)
					text='Dscription contains illegal characters';
				errorMessages.push(text);
			}
			if(this.props.tags>0){
				errorNum=this.props.tags;
				if(errorNum===1)
					text='Tags input is empty';
				else if(errorNum===2)
					text='Tags contain illegal characters';
				errorMessages.push(text);
			}
			if(this.props.files>0){
				errorNum=this.props.files;
				if(errorNum===1)
					text='Must select file(s) to upload';
				errorMessages.push(text);
			}
		}

		if(errorMessages.length===0)
			return <div></div>;

		let errorsObjs=[];
		for(let msg=0;msg<errorMessages.length;msg++)
			errorsObjs.push(<div className='errorMessage' key={msg}>{errorMessages[msg]}</div>);
		return <div id='errorMessageContainer'>{errorsObjs}</div>;
	}
}